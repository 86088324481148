import style from "./Landing.module.css";
import lines from "../../imagenes/line/Lines.svg";
import logo from "../../imagenes/logo/inchcapeLogo.png";
import { useNavigate } from "react-router-dom";
import fetchClient from "../../../../api/fetch";
import { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const Landing = () => {
  const Navigate = useNavigate();
  const searchValue = window.location.search.split("=")[1];
  const hasSessionId =
    searchValue !== undefined && searchValue !== null && searchValue !== "";
  const origin = window.location.origin;
  const [loginIndicator, setLoginIndicator] = useState("Login");

  const loginOptions = [
    { text: "Inchcape Internal", value: "internal" },
    { text: "3rd Party User", value: "third-party" }
  ];

  const [loginType, setLoginType] = useState(loginOptions[0]);

  const onLoginClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (loginType.value === "third-party") {
      setLoginIndicator("Logging in...");
      setTimeout(() => {
        Navigate("/thirdpartylogin");
      }, 800);
    } else if (loginType.value === "internal") {
      if (origin.includes("localhost")) {
        setLoginIndicator("Logging in...");
        setTimeout(() => {
          Navigate("/pinglogin");
        }, 800);
      } else {
        setLoginIndicator("Logging in...");
        fetchClient()
          .get("/v1/sso")
          .then((response) => {
            if (response.data) {
              window.location.href = response.data;
            }
          })
          .catch((err) => {
            console.log(err);
            setLoginIndicator("Login");
          });
      }
    }
  };

  useEffect(() => {
    if (hasSessionId) {
      setLoginIndicator("Logging in...");
    }
  }, [hasSessionId]);

  const handleLoginTypeChange = (e) => {
    setLoginType(e.value);
  };

  return (
    <div className={style.main}>
      <div className={style.container}>
        <img
          className={`${style.lines} align-self-end align-self-sm-center`}
          src={lines}
          alt="lines icon"
        />
        <div
          className={`${style.contents} justify-content-sm-between justify-content-evenly`}
        >
          <img src={logo} alt="inchcape logo" />
          <div>
            <p>RETENTION</p>
            <p>ENGINE</p>
          </div>
          <div className={style.dropdownContainer}>
            <label className={style.dropdownLabel}>Login Type</label>
            <DropDownList
              autoClose={false}
              textField="text"
              value={loginType}
              data={loginOptions}
              onChange={handleLoginTypeChange}
              className={style.customDropdown}
              style={{ color: 'white', backgroundColor: 'white' }}
            />
          </div>
          <button
            className="primary-btn"
            style={{
              cursor: loginIndicator === "Logging in..." ? "default" : "pointer"
            }}
            onClick={hasSessionId ? undefined : (e) => onLoginClick(e)}
          >
            {loginIndicator}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Landing;
