import "../../styles/settings/settings.css";
import { useEffect, useRef, useState } from "react";
import fetchClient from "../../api/fetch";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import SettingsModalConfirmation from "./SettingsModalConfirmation";
import SettingsPanel from "./SettingsPanel";
import TransactionalLogPanel from "./TransactionalLogPanel";
import { format, subDays } from "date-fns";
import { settingsFieldName } from "../../utils/settingsFieldName";
import {
  checkMarketAu,
  checkMarketChile,
  checkMarketPoland,
} from "../../utils/helpers";

const Settings = ({
  marketSettings,
  setLoading,
  setSuccessModalValues,
  getMarketSettings,
}) => {
  const { t } = useTranslation();
  const initialPayload = {
    defaultInterestRate:
      marketSettings.defaultInterestRateFormatted?.toFixed(2),
    dealerOriginationFees: marketSettings.dealerOriginationFees?.toFixed(2),
    ppsr: marketSettings.ppsrFormatted?.toFixed(2),
    establishmentFee: marketSettings.establishmentFeeFormatted?.toFixed(2),
    accountKeepingFee: marketSettings.accountKeepingFeeFormatted?.toFixed(2),
    annualKmAllowance: marketSettings.annualKmAllowanceFormatted?.toFixed(2),
    taxFactor: marketSettings.taxFactorFormatted?.toFixed(2),
    expensesVehicleRegistration:
      marketSettings.expensesVehicleRegistrationFormatted?.toFixed(2),
    additionalProductAccessories:
      marketSettings.additionalProductAccessoriesFormatted?.toFixed(2),
    prepaidExpensesPlusInterest:
      marketSettings.prepaidExpensesPlusInterestFormatted?.toFixed(2),
    interestRateWibor: marketSettings.interestRateWiborFormatted?.toFixed(2),
    subsidy: marketSettings.subsidyFormatted?.toFixed(2),
  };

  const defaultStartDate = subDays(new Date(), 30);
  const fieldNameConfig = checkMarketAu()
    ? settingsFieldName.filter((field) =>
        [
          "all",
          "defaultInterestRate",
          "dealerOriginationFees",
          "ppsr",
          "establishmentFee",
          "accountKeepingFee",
          "annualKmAllowance",
        ].includes(field.value)
      )
    : checkMarketChile()
    ? settingsFieldName.filter((field) =>
        [
          "all",
          "defaultInterestRate",
          "taxFactor",
          "expensesVehicleRegistration",
          "additionalProductAccessories",
          "prepaidExpensesPlusInterest",
        ].includes(field.value)
      )
    : checkMarketPoland()
    ? settingsFieldName.filter((field) =>
        ["all", "defaultInterestRate", "interestRateWibor", "subsidy"].includes(
          field.value
        )
      )
    : settingsFieldName.slice(0, 2);

  const [payload, setPayload] = useState(initialPayload);
  const [showError, setShowError] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  const [auditLogsData, setAuditLogsData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [dateValue, setDateValue] = useState({
    start: defaultStartDate,
    end: new Date(),
  });
  const [fieldNameValue, setFieldNameValue] = useState(
    fieldNameConfig.filter((item) => item.value !== "all")
  );
  const [params, setParams] = useState({
    fieldName: "all",
    startDate: format(defaultStartDate, "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  });
  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  });
  const [pageSizeValue, setPageSizeValue] = useState();

  const payloadHolder = useRef(payload);

  useEffect(() => {
    getAuditLogs(queryBuilder());
  }, [page, params, dateValue, fieldNameValue]);

  useEffect(() => {
    setPayload({
      ...payload,
      defaultInterestRate:
        marketSettings.defaultInterestRateFormatted?.toFixed(2),
      dealerOriginationFees: marketSettings.dealerOriginationFees?.toFixed(2),
      ppsr: marketSettings.ppsrFormatted?.toFixed(2),
      establishmentFee: marketSettings.establishmentFeeFormatted?.toFixed(2),
      accountKeepingFee: marketSettings.accountKeepingFeeFormatted?.toFixed(2),
      annualKmAllowance: marketSettings.annualKmAllowanceFormatted?.toFixed(2),
      taxFactor: marketSettings.taxFactorFormatted?.toFixed(2),
      expensesVehicleRegistration:
        marketSettings.expensesVehicleRegistrationFormatted?.toFixed(2),
      additionalProductAccessories:
        marketSettings.additionalProductAccessoriesFormatted?.toFixed(2),
      prepaidExpensesPlusInterest:
        marketSettings.prepaidExpensesPlusInterestFormatted?.toFixed(2),
      interestRateWibor: marketSettings.interestRateWiborFormatted?.toFixed(2),
      subsidy: marketSettings.subsidyFormatted?.toFixed(2),
    });
    payloadHolder.current = {
      defaultInterestRate:
        marketSettings.defaultInterestRateFormatted?.toFixed(2),
      dealerOriginationFees: marketSettings.dealerOriginationFees?.toFixed(2),
      ppsr: marketSettings.ppsrFormatted?.toFixed(2),
      establishmentFee: marketSettings.establishmentFeeFormatted?.toFixed(2),
      accountKeepingFee: marketSettings.accountKeepingFeeFormatted?.toFixed(2),
      annualKmAllowance: marketSettings.annualKmAllowanceFormatted?.toFixed(2),
      taxFactor: marketSettings.taxFactorFormatted?.toFixed(2),
      expensesVehicleRegistration:
        marketSettings.expensesVehicleRegistrationFormatted?.toFixed(2),
      additionalProductAccessories:
        marketSettings.additionalProductAccessoriesFormatted?.toFixed(2),
      prepaidExpensesPlusInterest:
        marketSettings.prepaidExpensesPlusInterestFormatted?.toFixed(2),
      interestRateWibor: marketSettings.interestRateWiborFormatted?.toFixed(2),
      subsidy: marketSettings.subsidyFormatted?.toFixed(2),
    };
  }, [marketSettings]);

  const getAuditLogs = (params) => {
    setLoading(true);
    fetchClient()
      .get(`/v1/auditlogs/marketsettings?` + params)
      .then((res) => {
        setAuditLogsData(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const queryBuilder = (newpage = null) => {
    return `fieldName=${!params.fieldName ? "" : params.fieldName}&userId=${
      !params.userId ? "" : params.userId
    }&startDate=${params.startDate}&endDate=${
      params.endDate
    }&page=${JSON.stringify(newpage ? newpage : page)}`;
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...e.page,
      skip: e.page.skip,
      take,
    });
  };

  const handleChange = (e) => {
    if (e.target.value >= 0) {
      setPayload({ ...payload, [e.target.name]: e.target.value });
    }

    if (!e.target.value || e.target.value === "") {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  const handleFocusOut = (e) => {
    setPayload({
      defaultInterestRate: payload.defaultInterestRate
        ? Number(payload.defaultInterestRate).toFixed(2)
        : null,
      dealerOriginationFees: payload.dealerOriginationFees
        ? Number(payload.dealerOriginationFees).toFixed(2)
        : null,
      ppsr: payload.ppsr ? Number(payload.ppsr).toFixed(2) : null,
      establishmentFee: payload.establishmentFee
        ? Number(payload.establishmentFee).toFixed(2)
        : null,
      accountKeepingFee: payload.accountKeepingFee
        ? Number(payload.accountKeepingFee).toFixed(2)
        : null,
      annualKmAllowance: payload.annualKmAllowance
        ? Number(payload.annualKmAllowance).toFixed(2)
        : null,
      interestRateWibor: payload.interestRateWibor
        ? Number(payload.interestRateWibor).toFixed(2)
        : null,
      subsidy: payload.subsidy ? Number(payload.subsidy).toFixed(2) : null,
      taxFactor: payload.taxFactor
        ? Number(payload.taxFactor).toFixed(2)
        : null,
      expensesVehicleRegistration: payload.expensesVehicleRegistration
        ? Number(payload.expensesVehicleRegistration).toFixed(2)
        : null,
      additionalProductAccessories: payload.additionalProductAccessories
        ? Number(payload.additionalProductAccessories).toFixed(2)
        : null,
      prepaidExpensesPlusInterest: payload.prepaidExpensesPlusInterest
        ? Number(payload.prepaidExpensesPlusInterest).toFixed(2)
        : null,
    });
  };

  const handleSave = (e) => {
    if (!showError) {
      setSaveChanges(true);
    }
  };

  const handleProceedSave = () => {
    const items = { ...payload };
    if (checkMarketPoland()) {
      items.interestRateWibor = payload.interestRateWibor / 100;
      items.subsidy = payload.subsidy / 100;
    }
    if (checkMarketChile()) items.taxFactor = payload.taxFactor / 100;

    setSaveChanges(false);
    setLoading(true);
    fetchClient()
      .post("/v1/marketsettings", items)
      .then((res) => {
        getMarketSettings();
        setSuccessModalValues({
          message: "Settings saved successfully",
          isVisible: true,
        });
        getAuditLogs(queryBuilder());
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleCancelSave = () => {
    setSaveChanges(false);
  };

  const handleOpenUsersDropdown = () => {
    fetchClient()
      .get("/v1/common/marketsettingusers")
      .then((res) => {
        setUsersData(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleUserChange = (e) => {
    setParams({ ...params, userId: e.target.value?.id });
  };

  const handleDateChange = (e) => {
    const startDateFormatted = format(
      new Date(e.target.value?.start),
      "yyyy-MM-dd"
    );
    const endDateFormatted = format(
      new Date(e.target.value?.end),
      "yyyy-MM-dd"
    );

    setDateValue(e.target.value);
    setParams({
      ...params,
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    });
  };

  const handleFieldName = (e) => {
    const selectAll = e.target.value.some((item) => item.value === "all");

    if (selectAll) {
      if (fieldNameValue.length === fieldNameConfig.length - 1) {
        setFieldNameValue([]);
        setParams({ ...params, fieldName: "" });
      } else {
        setFieldNameValue(
          fieldNameConfig.filter((item) => item.value !== "all")
        );
      }
    } else {
      setFieldNameValue(e.target.value);
      setParams({
        ...params,
        fieldName: e.target.value.map((item) => item.value),
      });
    }
  };

  return (
    <div className="settings">
      {saveChanges && (
        <SettingsModalConfirmation
          handleProceedSave={handleProceedSave}
          handleCancelSave={handleCancelSave}
        />
      )}
      <p className="h1 mb-4">{getTranslation("Settings", t)}</p>
      <div className="d-flex justify-content-between flex-sm-column flex-lg-row">
        <SettingsPanel
          payload={payload}
          marketSettings={marketSettings}
          payloadHolder={payloadHolder}
          handleChange={handleChange}
          handleFocusOut={handleFocusOut}
          handleSave={handleSave}
          showError={showError}
        />
        <TransactionalLogPanel
          usersData={usersData}
          handleUserChange={handleUserChange}
          handleOpenUsersDropdown={handleOpenUsersDropdown}
          handleDateChange={handleDateChange}
          handleFieldName={handleFieldName}
          dateValue={dateValue}
          fieldNameValue={fieldNameValue}
          data={auditLogsData}
          page={page}
          pageSizeValue={pageSizeValue}
          pageChange={pageChange}
          fieldNameConfig={fieldNameConfig}
        />
      </div>
    </div>
  );
};

export default Settings;
