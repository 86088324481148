import { replaceAll } from '../../common';

export const getTranslation = (input, t, defaultText = input) => {
    if (!t) return defaultText;
    if (!input || input === null || input === undefined) return defaultText;

    let translationKey = replaceAll(input);
    let translatedValue = t(translationKey);

    return translatedValue !== translationKey && translatedValue
        ? translatedValue
        : defaultText;
}