import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import "@progress/kendo-theme-default/dist/all.css";
import {
  loadCampaignList,
  loadCampaignLeadsList,
  loadUsersList,
  loadMarketSettings,
  loadLeadsList,
  setAuth,
  setCampaignList,
  setCampaignLeadsList,
  setCurrentUser,
  setLoading,
  loadVehicleSelectorList,
  setLeadsList,
  setLeadsReferenceKey,
  loadLeadsCount,
  setLeadsCount,
  loadHeaderUserMarkets,
  loadHeaderUserBrands,
  updateUserSettings,
  saveCampaignLeads,
  loadUserBranches,
  loadPortfolioList,
  setDashboardLoadType,
  setLoadingCount,
  loadServiceAlertsList,
  loadSearchModuleList,
  setSearchModuleList,
  setSearchModuleParams,
  setSuccessModalValues,
  exportCampaign,
  removeOpportunity,
  loadCustomerRecordData,
  loadSpecialists,
  loadActivityRecord,
  updateActivityRecord,
  loadReportsUsageGridList,
  exportUsageReport,
  loadReportsConversionGridList,
  exportConversionReport,
  logoutAction,
} from "./actions";
import {
  getAuth,
  getCampaignListSelector,
  getCampaignListPageSelector,
  getCampaignLeadsListSelector,
  getCampaignLeadsListPageSelector,
  getCurrentUserSelector,
  getLoading,
  getUsersListPageSelector,
  getUsersListSelector,
  getMarketSettingsSelector,
  getVehicleListSelector,
  getLeadsListPageSelector,
  getLeadsListSelector,
  getLeadsReferenceKeySelector,
  getLeadsCountSelector,
  getHeaderUserMarketsSelector,
  getHeaderUserBrandsSelector,
  getUserBranchesSelector,
  getPortfolioListSelector,
  getPortfolioListPageSelector,
  getLoadingCountSelector,
  getDashboardLoadType,
  getServiceAlertsListSelector,
  getServiceAlertsListPageSelector,
  getSearchModuleListSelector,
  getSearchModuleListPageSelector,
  getSearchModuleParams,
  getSuccessModalValues,
  getCustomerRecordDataSelector,
  getSpecialistsSelector,
  getClientPositionDataSelector,
  getFinanceRecordDataSelector,
  getServiceAlertsFiltersSelector,
  getActivityRecordSelector,
  getReportsUsageGridListSelector,
  getReportsUsageGridListPageSelector,
  getReportsConversionGridListSelector,
  getReportsConversionGridListPageSelector,
} from "./selectors";
import Campaigns from "./components/campaigns/Campaigns";
import CampaignLeads from "./components/campaigns/CampaignLeads";
import Sidebar from "./components/shared/Sidebar";
import Dashboard from "./components/dashboard/Dashboard";
import Landing from "./components/loginPage/components/landingPage/Landing";
import InvalidPage from "./components/loginPage/components/invalidPage/InvalidPage";
import ThirdPartyLogin from "./components/loginPage/components/thirdPartyLogin/thirdPartyLogin";
import ChangePassword from "./components/loginPage/components/changePassword/changePassword";
import SearchModule from "./components/searchModule/SearchModule";
import CustomerRecord from "./components/customerRecord/CustomerRecord";
import EditProposedDeal from "./components/editProposedDeal/EditProposedDeal";
import UserManagement from "./components/userManagement/UserManagement";
import Header from "./components/shared/Header";
import { Routes, Route } from "react-router-dom";
import UserForm from "./components/userManagement/UserForm";
import axios from "axios";
import fetchClient, { getchToken } from "./api/fetch";
import Cookie from "./api/Cookies";
import PingLogin from "./components/loginPage/components/mockUpLogin/PingLogin";
import ServiceAlerts from "./components/serviceAlerts/ServiceAlerts";
import Reports from "./components/reports/Reports";
import ConversionReport from "./components/reports/ConversionReport";
import UsageReport from "./components/reports/UsageReport";
import Portfolio from "./components/portfolio/Portfolio";
import CampaignBuilder from "./components/campaigns/CampaignBuilder";
import LoadingPage from "./components/shared/LoadingPage";
import IdleTimeOutHandler from "./components/shared/IdleTimeoutHandler";
import SuccessModal from "./components/shared/SuccessModal";
import AccessControl from "./components/userManagement/AccessControl";
import Settings from "./components/settings/Settings";
import LandingPage from "./components/loginPage/components/landingPage/LandingPage";
import ProtectedRoute from "./components/shared/ProtectedRoute";
import { set } from "lodash";

const App = (props) => {
  const isAuth = props.isAuth;
  const Navigation = useNavigate();
  let location = useLocation();
  const [hasUserSettings, setHasUserSettings] = useState(false);

  const checkAuth = (sessionId) => {
    if (!Cookie.get("token")) {
      getchToken()
        .post(`/v1/auth/validate?samlSessionId=${sessionId}`)
        .then((response) => {
          // Save response token to cookies
          const market = response.data.market;
          Cookie.set("token", response.data.token);
          Cookie.set("rftoken", response.data.refreshtoken);
          Cookie.set("market", market);

          if (!market) {
            setHasUserSettings(false);
            Navigation("/market-selection");
          } else {
            props.setAuth(true);
            setHasUserSettings(true);
            axios
              .all([
                // - call user info - save info to redux
                fetchClient().get("/v1/users/info"),
                // - call user permissions - save info to redux
                fetchClient().get(`/v1/permissions`),
                // - call user menu - save info to redux
                fetchClient().get(`/v1/permissions/menu`),
                // - call user settings - save info to redux (userId still static)
                fetchClient().get(`/v1/usersettings`),
              ])
              .then(
                axios.spread((userInfo, permissions, menu, settings) => {
                  props.setCurrentUser({
                    userInfo: userInfo.data.data,
                    permissions: permissions.data,
                    menu: menu.data,
                    settings: settings.data,
                  });

                  // Call MarketSettings API
                  props.getMarketSettings();
                })
              );
          }
        })
        .catch((err) => {
          Navigation("/invalid-identity");
        });
    }
  };

  useEffect(() => {
    const sessionId = location.search.split("=")[1];
    const isInvalidMarketPath = location.pathname === "/invalid-market";
    if (sessionId) {
      checkAuth(sessionId);
    } else {
      // prevent user from going through the app once logged out
      if (location.pathname === "/system-error") {
        props.setAuth(false);
        Navigation("/system-error");
      } else if (
        !Cookie.get("token") &&
        !isAuth &&
        location.pathname !== "/inactive" &&
        location.pathname !== "/" &&
        location.pathname !== "/invalid-identity"
      ) {
        Navigation("/login");
      } else if (Cookie.get("token") && !isAuth && !isInvalidMarketPath) {
        const market = Cookie.get("market");
        if (market === "null" || market === "system") {
          setHasUserSettings(false);
          Navigation("/market-selection");
        } else {
          setHasUserSettings(true);
          axios
            .all([
              // - call user info - save info to redux
              fetchClient().get("/v1/users/info"),
              // - call user permissions - save info to redux
              fetchClient().get(`/v1/permissions`),
              // - call user menu - save info to redux
              fetchClient().get(`/v1/permissions/menu`),
              // - call user settings - save info to redux (userId still static)
              fetchClient().get(`/v1/usersettings`),
            ])
            .then(
              axios.spread((userInfo, permissions, menu, settings) => {
                props.setCurrentUser({
                  userInfo: userInfo.data.data,
                  permissions: permissions.data,
                  menu: menu.data,
                  settings: settings.data,
                });
                props.setAuth(true);
                Cookie.set("market", settings.data.subdomain);

                // Call MarketSettings API
                props.getMarketSettings();
              })
            );

          // redirect dashboard (currently redirects to last visited page)

          Navigation(location.pathname);
        }
      }
    }
  }, [location.search, isAuth]);

  return (
    <div className="App container-fluid">
      {/* Idle handler */}
      {isAuth && <IdleTimeOutHandler />}
      {props.successModalValues.isVisible && (
        <SuccessModal
          successModalValues={props.successModalValues}
          setSuccessModalValues={props.setSuccessModalValues}
        />
      )}
      {!hasUserSettings && isAuth ? (
        <Routes>
          <Route
            path="/market-selection"
            element={<LandingPage {...props} />}
          />
        </Routes>
      ) : isAuth ? (
        <>
          <div className="main-container d-flex">
            <Sidebar {...props} />
            <div className="main-contents w-100">
              {props.isLoading && <LoadingPage {...props} />}
              <Header {...props} />
              <Routes>
                <Route path="/dashboard" element={<Dashboard {...props} />} />
                <Route path="/campaigns" element={<Campaigns {...props} />} />
                {/** Route for campaign builder */}
                <Route
                  path="/campaigns/new"
                  element={
                    <ProtectedRoute
                      {...props}
                      identifier="permission"
                      path="Campaigns"
                      action="canCreate"
                    >
                      <CampaignBuilder {...props} />
                    </ProtectedRoute>
                  }
                />
                {/** Route for campaign leads */}
                <Route
                  path="/campaigns/:id/leads"
                  element={<CampaignLeads {...props} />}
                />
                {/** Route for edit campaign */}
                <Route
                  path="/campaigns/:id"
                  element={
                    <ProtectedRoute
                      {...props}
                      identifier="permission"
                      path="Campaigns"
                      action="canEdit"
                    >
                      <CampaignBuilder {...props} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/service-alerts"
                  element={<ServiceAlerts {...props} />}
                />
                <Route path="/portfolio" element={<Portfolio {...props} />} />
                <Route
                  path="/search-module"
                  element={<SearchModule {...props} />}
                />
                <Route
                  path="/customer-record/:id"
                  element={<CustomerRecord {...props} />}
                />
                <Route
                  path="/customer-record/:id/edit-proposed-deal"
                  element={<EditProposedDeal {...props} />}
                />
                {/* <Route
                  path="/marketing-tools"
                  element={<Campaigns {...props} />}
                /> */}
                <Route
                  path="/reports"
                  element={
                    <ProtectedRoute
                      {...props}
                      identifier="access"
                      path="Reports"
                    >
                      <Reports {...props} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reports/conversion-report"
                  element={
                    <ProtectedRoute
                      {...props}
                      identifier="access"
                      path="Reports"
                    >
                      <ConversionReport {...props} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reports/usage-report"
                  element={
                    <ProtectedRoute
                      {...props}
                      identifier="access"
                      path="Reports"
                    >
                      <UsageReport {...props} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-management"
                  element={
                    <ProtectedRoute
                      {...props}
                      identifier="access"
                      path="User Management"
                    >
                      <UserManagement {...props} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-management/new"
                  element={
                    <ProtectedRoute
                      {...props}
                      identifier="access"
                      path="User Management"
                    >
                      <UserForm {...props} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-management/:id"
                  element={
                    <ProtectedRoute
                      {...props}
                      identifier="access"
                      path="User Management"
                    >
                      <UserForm {...props} />
                    </ProtectedRoute>
                  }
                />
                {/* <Route
                  path="/access-control"
                  element={
                    <ProtectedRoute {...props} identifier="level">
                      <AccessControl {...props} />
                    </ProtectedRoute>
                  }
                /> */}
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute
                      {...props}
                      identifier="access"
                      path={"Settings"}
                    >
                      <Settings {...props} />
                    </ProtectedRoute>
                  }
                />
                {/*<Route path="/settings" element={<Settings {...props} />} />*/}
                <Route
                  path="/*"
                  element={<Navigate to="/dashboard" {...props} />}
                />
              </Routes>
            </div>
          </div>
        </>
      ) : (
        <Routes>
          <Route path="/" element={<Landing {...props} />} />
          <Route path="/login" element={<Landing {...props} />} />
          <Route
            path="/thirdpartylogin"
            element={
              <ThirdPartyLogin
                {...props}
                setHasUserSettings={setHasUserSettings}
              />
            }
          />
          <Route
            path="/changepassword"
            element={<ChangePassword {...props} />}
          />
          <Route path="/pinglogin" element={<PingLogin {...props} />} />
          <Route
            path="/inactive"
            element={<InvalidPage {...props} status="100" />}
          />
          <Route
            path="/invalid-identity"
            element={<InvalidPage {...props} status="401" />}
          />
          <Route
            path="/system-error"
            element={<InvalidPage {...props} status="300" />}
          />
          <Route
            path="/invalid-market"
            element={<InvalidPage {...props} status="403" />}
          />
          <Route
            path="/market-selection"
            element={<LandingPage {...props} />}
          />
        </Routes>
      )}
      {/* {} */}
    </div>
  );
};
// Global state
const mapStateToProps = (state) => ({
  isLoading: getLoading(state),
  isAuth: getAuth(state),
  campaignList: getCampaignListSelector(state),
  currentUser: getCurrentUserSelector(state),
  usersList: getUsersListSelector(state),
  usersListPage: getUsersListPageSelector(state),
  campaignListPage: getCampaignListPageSelector(state),
  campaignLeadsList: getCampaignLeadsListSelector(state),
  campaignLeadsListPage: getCampaignLeadsListPageSelector(state),
  marketSettings: getMarketSettingsSelector(state),
  vehicleSelectorList: getVehicleListSelector(state),
  leadsCount: getLeadsCountSelector(state),
  leadsList: getLeadsListSelector(state),
  leadsListPage: getLeadsListPageSelector(state),
  leadsReferenceKey: getLeadsReferenceKeySelector(state),
  headerUserMarkets: getHeaderUserMarketsSelector(state),
  headerUserBrands: getHeaderUserBrandsSelector(state),
  userBranches: getUserBranchesSelector(state),
  portfolioList: getPortfolioListSelector(state),
  portfolioListPage: getPortfolioListPageSelector(state),
  loadingCount: getLoadingCountSelector(state),
  dashboardLoadType: getDashboardLoadType(state),
  serviceAlertsList: getServiceAlertsListSelector(state),
  serviceAlertsListPage: getServiceAlertsListPageSelector(state),
  searchModuleList: getSearchModuleListSelector(state),
  searchModuleListPage: getSearchModuleListPageSelector(state),
  searchModuleParams: getSearchModuleParams(state),
  successModalValues: getSuccessModalValues(state),
  customerRecordData: getCustomerRecordDataSelector(state),
  clientPositionData: getClientPositionDataSelector(state),
  financeRecordData: getFinanceRecordDataSelector(state),
  specialists: getSpecialistsSelector(state),
  activityRecord: getActivityRecordSelector(state),
  reportsUsageGridList: getReportsUsageGridListSelector(state),
  reportsUsageGridListPage: getReportsUsageGridListPageSelector(state),
  reportsConversionGridList: getReportsConversionGridListSelector(state),
  reportsConversionGridListPage:
    getReportsConversionGridListPageSelector(state),
});

// Global props
const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => {
    dispatch(setLoading(isLoading));
  },
  getCampaignList: (args) => {
    dispatch(loadCampaignList(args));
  },
  setCampaignList: (campaignList) => {
    dispatch(setCampaignList(campaignList));
  },
  getCampaignLeadsList: (campaignId, args) => {
    dispatch(loadCampaignLeadsList(campaignId, args));
  },
  setCampaignLeadsList: (campaignLeadsList) => {
    dispatch(setCampaignLeadsList(campaignLeadsList));
  },
  setAuth: (isAuth) => {
    dispatch(setAuth(isAuth));
  },
  setCurrentUser: (currentUser) => {
    dispatch(setCurrentUser(currentUser));
  },
  getUsersList: (args) => {
    dispatch(loadUsersList(args));
  },
  getMarketSettings: () => {
    dispatch(loadMarketSettings());
  },
  getVehicleSelectorList: (args) => {
    dispatch(loadVehicleSelectorList(args));
  },
  getLeadsList: (args) => {
    dispatch(loadLeadsList(args));
  },
  setLeadsList: (leadsList) => {
    dispatch(setLeadsList(leadsList));
  },
  setLeadsReferenceKey: (refKey) => {
    dispatch(setLeadsReferenceKey(refKey));
  },
  getLeadsCount: (payload) => {
    dispatch(loadLeadsCount(payload));
  },
  setLeadsCount: (leadsCount) => {
    dispatch(setLeadsCount(leadsCount));
  },
  getHeaderUserMarkets: () => {
    dispatch(loadHeaderUserMarkets());
  },
  getHeaderUserBrands: (marketId) => {
    dispatch(loadHeaderUserBrands(marketId));
  },
  updateUserSettings: (userSettings) => {
    dispatch(updateUserSettings(userSettings));
  },
  saveCampaignLeads: (data) => {
    dispatch(saveCampaignLeads(data));
  },
  getUserBranches: (brandId) => {
    dispatch(loadUserBranches(brandId));
  },
  getPortfolioList: (args) => {
    dispatch(loadPortfolioList(args));
  },
  setLoadingCount: (count) => {
    dispatch(setLoadingCount(count));
  },
  setDashboardLoadType: (type) => {
    dispatch(setDashboardLoadType(type));
  },
  getServiceAlertsList: (args) => {
    dispatch(loadServiceAlertsList(args));
  },
  getSearchModuleList: (args) => {
    dispatch(loadSearchModuleList(args));
  },
  setSearchModuleList: (searchModuleList) => {
    dispatch(setSearchModuleList(searchModuleList));
  },
  setSearchModuleParams: (searchModuleParams) => {
    dispatch(setSearchModuleParams(searchModuleParams));
  },
  setSuccessModalValues: (successModalValues) => {
    dispatch(setSuccessModalValues(successModalValues));
  },
  exportCampaignToExcel: (campaignId) => {
    dispatch(exportCampaign(campaignId));
  },
  removeOpportunity: (removeOpportunityData) => {
    dispatch(removeOpportunity(removeOpportunityData));
  },
  getCustomerRecordData: (id) => {
    dispatch(loadCustomerRecordData(id));
  },
  getSpecialists: () => {
    dispatch(loadSpecialists());
  },
  getActivityRecord: (id) => {
    dispatch(loadActivityRecord(id));
  },
  updateActivityRecord: (updateActivityRecordData, id) => {
    dispatch(updateActivityRecord(updateActivityRecordData, id));
  },
  getReportsUsageGridList: (args) => {
    dispatch(loadReportsUsageGridList(args));
  },
  getReportsConversionGridList: (args) => {
    dispatch(loadReportsConversionGridList(args));
  },
  exportUsageReportToExcel: (filters) => {
    dispatch(exportUsageReport(filters));
  },
  exportConversionReportToExcel: (filters) => {
    dispatch(exportConversionReport(filters));
  },
  logout: () => {
    dispatch(logoutAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
