import style from "./changePassword.module.css";
import "@progress/kendo-theme-default/dist/all.css";
import logo from "../../imagenes/icon/InchcapeIcon.png";
import lines from "../../imagenes/line/Lines.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import fetchClient from "../../../../api/fetch";
import { getTranslation } from "../../../../common/translation";
import { useTranslation } from "react-i18next";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,}$/;

const ChangePassword = ({ setSuccessModalValues }) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const navigate = useNavigate();
  const pwdRef = useRef();
  const { state } = useLocation();
  const { userId, email } = state;

  const initialPayload = {
    password: "",
    confirmPassword: "",
  };

  const [payload, setPayload] = useState(initialPayload);

  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    pwdRef.current.focus();
  }, []);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(payload.password));
    setValidMatch(payload.password === payload.confirmPassword);
  }, [payload.password, payload.confirmPassword]);

  useEffect(() => {
    setErrMsg("");
  }, [payload.password, payload.confirmPassword]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const handleSubmit = () => {
    const newPassword = { userId, newPassword: payload.password };
    fetchClient()
      .put(`/v1/thirdparty`, newPassword)
      .then((res) => {
        setSuccessModalValues({
          message: getTranslation("Password changed successfully", t),
          isVisible: true,
        });
        setTimeout(() => {
          setSuccessModalValues({ message: "", isVisible: false });
          Navigate("/thirdpartylogin", {
            state: { userId, email, isFromChangePassword: true },
          });
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={`${style.main} d-flex flex-column flex-sm-row`}>
      <div className={`${style.left} order-2 order-sm-1`}>
        <img src={logo} alt="Inchcape logo" />
        <div>
          <div className={style.contents}>
            <p className={style.title}>Login - 3rd Party Users</p>
            <p className={style.msg}>Set your password</p>
            <form onSubmit={handleSubmit}>
              <div className={style.inputGroup}>
                <label htmlFor="password" className={style.label}>
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  ref={pwdRef}
                  name="password"
                  placeholder="Password"
                  onChange={handleInputChange}
                  value={payload.password}
                  aria-invalid={pwdFocus && validPwd ? "false" : "true"}
                  aria-describedby="pwdnote"
                  onFocus={() => setPwdFocus(true)}
                  onBlur={() => setPwdFocus(false)}
                  className={style.input}
                  required
                />
                <p id="pwdnote" className="">
                  {pwdFocus && !validPwd && (
                    <div className={style.passwordError}>
                      <ul>
                        <li>Password must be at least 8 characters long.</li>
                        <li>
                          Password must include at least one uppercase letter,
                          one number and one special character.
                        </li>
                        <li>
                          Allowed special characters:{" "}
                          <span aria-label="exclamation mark">!</span>{" "}
                          <span aria-label="at symbol">@</span>{" "}
                          <span aria-label="hashtag">#</span>{" "}
                          <span aria-label="dollar sign">$</span>{" "}
                          <span aria-label="percent">%</span>
                        </li>
                      </ul>
                    </div>
                  )}
                </p>

                <label htmlFor="confirm_pwd">Confirm Password</label>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  id="confirm_pwd"
                  onChange={handleInputChange}
                  name="confirmPassword"
                  value={payload.confirmPassword}
                  aria-invalid={validMatch ? "false" : "true"}
                  aria-describedby="confirmnote"
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                  className={style.input}
                  required
                />
                <p
                  id="confirmnote"
                  className={
                    matchFocus && !validMatch ? "instructions" : "offscreen"
                  }
                >
                  {matchFocus && !validMatch && (
                    <div className={style.passwordError}>
                      Passwords do not match.
                    </div>
                  )}
                </p>
              </div>
            </form>

            <div className={style.buttonGroup}>
              <button
                type="button"
                className="secondary-btn"
                onClick={() => navigate("/thirdpartylogin")}
              >
                Cancel
              </button>

              <button
                disabled={!validPwd || !validMatch ? true : false}
                className="primary-btn"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${style.right} order-1 order-sm-2 d-flex flex-column align-items-center`}
      >
        <img src={lines} alt="Lines icon" />
        <div>
          <p>RETENTION</p>
          <p>ENGINE</p>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
