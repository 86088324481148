import React, { useEffect } from "react";
import "../../styles/shared/modal.css";
import { getIcon } from "../../utils/iconUtils";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";

const SuccessModal = ({ successModalValues, setSuccessModalValues }) => {
  const { t } = useTranslation();

  const closeAction = () => {
    setSuccessModalValues({
      ...successModalValues,
      isVisible: false,
    });

    if (successModalValues.closeCallBack) successModalValues.closeCallBack();
  };

  const handleClose = () => {
    closeAction();
  };

  useEffect(() => {
    setTimeout(() => closeAction(), 3000);
  });

  return (
    <div>
      <Dialog>
        <img
          className="close align-self-end"
          onClick={handleClose}
          src={getIcon("close.svg")}
          alt="close icon"
        />
        <div className="success-modal">
          <span>{getTranslation(successModalValues.message, t)}</span>
          {!successModalValues.isCheckHidden && (
            <div>
              <img src={getIcon("check.svg")} alt="check icon" />
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default SuccessModal;
