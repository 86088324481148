export const searchModuleColumns = [
  {
    column: "statusName",
    title: "Status",
    filter: "text",
    isShown: true,
    width: "180px",
    canBeHidden: true
  },
  {
    column: "typeName",
    title: "Lead Type",
    filter: "text",
    isShown: true,
    width: "160px",
    canBeHidden: true
  },
  {
    column: "specialist",
    title: "Specialist",
    filter: "text",
    isShown: true,
    width: "200px",
    canBeHidden: true
  },
  {
    column: "lastActivity",
    title: "Last Activity",
    filter: "date",
    isShown: true,
    width: "160px",
    canBeHidden: true
  },
  {
    column: "financeType",
    title: "Finance Type",
    filter: "text",
    isShown: true,
    width: "130px",
    canBeHidden: true
  },
  {
    column: "customerName",
    title: "Customer name",
    filter: "text",
    isShown: true,
    width: "200px",
    canBeHidden: true
  },
  {
    column: "bankOrLender",
    title: "Bank/Seller",
    filter: "text",
    isShown: true,
    width: "150px",
    canBeHidden: true
  }
];
