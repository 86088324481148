import style from "./thirdPartyLogin.module.css";
import logo from "../../imagenes/icon/InchcapeIcon.png";
import lines from "../../imagenes/line/Lines.svg";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import fetchClient from "../../../../api/fetch";
import Cookie from "../../../../api/Cookies";
import axios from "axios";
import { getTranslation } from "../../../../common/translation";
import { useTranslation } from "react-i18next";

const ThirdPartyLogin = ({
  setAuth,
  setHasUserSettings,
  getMarketSettings,
  setCurrentUser,
  setSuccessModalValues
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userId, email, isFromChangePassword } = state || {};

  const [step, setStep] = useState(isFromChangePassword ? "otp" : "login");
  const [payload, setPayload] = useState({
    email: "",
    password: "",
  });
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const validateEmailAndPassword = () => {
    if (!payload.email || !/\S+@\S+\.\S+/.test(payload.email)) {
      return "Invalid email format.";
    }
    if (!payload.password || payload.password.length < 6) {
      return "Password must be at least 6 characters.";
    }
    return null;
  };

  const validateOtp = (otpValue) => {
    if (!otpValue || otpValue.length !== 6 || isNaN(otpValue)) {
      return "OTP must be a 6-digit number.";
    }
    return null;
  };

  useEffect(() => {
    if (step === "otp" || (userId && email)) {
      const payloadUserId = userId ?? payload.userId;
      const payloadEmail = email ?? payload.email;

      fetchClient()
        .post(`/v1/thirdparty`, { userId: payloadUserId, email: payloadEmail })
        .then((res) => {
          setQrCodeUrl(res.data.data);
        })
        .catch((error) => {
          console.error("Error fetching QR Code:", error);
          setQrCodeUrl(null);
        });
    }
  }, [step]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPayload((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = () => {
    const errorMessage = validateEmailAndPassword();

    if (errorMessage) {
      setError(errorMessage);
    } else {
      fetchClient()
        .post(`/v1/thirdparty/login`, {
          email: payload.email,
          password: payload.password,
        })
        .then((res) => {
          const { isFirstTimeLogin } = res.data.data;
          if (isFirstTimeLogin) {
            setSuccessModalValues({
              message: getTranslation("Login successfully", t),
              isVisible: true,
            });
            setTimeout(() => {
              setSuccessModalValues({ message: "", isVisible: false });
              navigate("/changepassword", {
                state: {
                  userId: res.data.data.userId,
                  email: res.data.data.email,
                },
              });
            }, 3000);
          } else {
            setSuccessModalValues({
              message: getTranslation("Login successfully", t),
              isVisible: true,
            });
            setTimeout(() => {
              setSuccessModalValues({ message: "", isVisible: false });
              setError("");
              setStep("otp");
              setPayload({ ...payload, userId: res.data.data.userId });
            }, 3000);
          }
        })
        .catch((err) => {
          setError("Login failed.");
        });
    }
  };

  const handleAccept = () => {
    const errorMessage = validateOtp(otp);

    if (errorMessage) {
      setError(errorMessage);
    } else {
      setError("");

      const verificationPayload = {
        email: email ?? payload.email,
        otp: otp,
      };
      
      setSuccessModalValues({ message: "Validating...", isVisible: true, isCheckHidden: true });

      fetchClient()
        .post("/v1/thirdparty/validate", verificationPayload)
        .then((response) => {
          setSuccessModalValues({
            message: getTranslation("Validation complete", t),
            isVisible: true,
          });

          Cookie.set("token", response.data.data.token);
          Cookie.set("rftoken", response.data.data.refreshToken);
          Cookie.set("market", response.data.data.market);

          setAuth(true);
          setHasUserSettings(true);
          axios
            .all([
              // - call user info - save info to redux
              fetchClient().get("/v1/users/info"),
              // - call user permissions - save info to redux
              fetchClient().get(`/v1/permissions`),
              // - call user menu - save info to redux
              fetchClient().get(`/v1/permissions/menu`),
              // - call user settings - save info to redux (userId still static)
              fetchClient().get(`/v1/usersettings`),
            ])
            .then(
              axios.spread((userInfo, permissions, menu, settings) => {
                setSuccessModalValues({ message: "", isVisible: false });
                setCurrentUser({
                  userInfo: userInfo.data.data,
                  permissions: permissions.data,
                  menu: menu.data,
                  settings: settings.data,
                });

                // Call MarketSettings API
                getMarketSettings();
              })
            );
        })
        .catch((error) => {
          console.error("Error during OTP verification:", error);
          setError("An unexpected error occurred. Please try again.");
        });
    }
  };

  return (
    <div className={`${style.main} d-flex flex-column flex-sm-row`}>
      <div className={`${style.left} order-2 order-sm-1`}>
        <img src={logo} alt="Inchcape logo" />
        <div>
          {step === "login" && (
            <div className={style.contents}>
              <p className={style.title}>Login - 3rd Party Users</p>
              <p className={style.msg}>Validate your identity</p>
              {error && <p className={style.error}>{error}</p>}
              <div className={style.inputGroup}>
                <label htmlFor="email" className={style.label}>
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={payload.email}
                  onChange={handleInputChange}
                  className={style.input}
                />
                <label htmlFor="password" className={style.label}>
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={payload.password}
                  onChange={handleInputChange}
                  className={style.input}
                />
              </div>
              <p></p>
              <div className={style.buttonGroup}>
                <button
                  type="button"
                  className="secondary-btn"
                  onClick={() => navigate("/login")}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="primary-btn"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
            </div>
          )}

          {step === "otp" && (
            <div className={style.contents}>
              <p className={style.title}>Login - 3rd Party Users</p>
              <p className={style.msg}>Scan the QR and validate your code</p>
              {error && <p className={style.error}>{error}</p>}
              <div className={style.qrCodeContainer}>
                {qrCodeUrl ? (
                  <img src={qrCodeUrl} alt="QR Code" className={style.qrCode} />
                ) : (
                  <p>Failed to fetch QR Code</p>
                )}
              </div>
              <div className={style.inputGroup}>
                <label htmlFor="otp" className={style.label}>
                  Code
                </label>
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  placeholder="123456"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className={style.input}
                />
              </div>
              <div className={style.buttonGroup}>
                <button
                  type="button"
                  className="secondary-btn"
                  onClick={() => setStep("login")}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="primary-btn"
                  onClick={handleAccept}
                >
                  Accept
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${style.right} order-1 order-sm-2 d-flex flex-column align-items-center`}
      >
        <img src={lines} alt="Lines icon" />
        <div>
          <p>RETENTION</p>
          <p>ENGINE</p>
        </div>
      </div>
    </div>
  );
};

export default ThirdPartyLogin;
