import { checkMarketAu, checkMarketPoland, checkMarketChile } from "./helpers";

export const settingsFieldName = [
  {
    value: "all",
    textField: "All"
  },
  {
    value: "defaultInterestRate",
    textField: "Default Interest Rate"
  },
  {
    value: "dealerOriginationFees",
    textField: "Dealer Origination Fees"
  },
  {
    value: "ppsr",
    textField: "PPSR"
  },
  {
    value: "establishmentFee",
    textField: "Establishment Fee"
  },
  {
    value: "accountKeepingFee",
    textField: "Account Keeping Fee"
  },
  {
    value: "annualKmAllowance",
    textField: "Annual km Allowance"
  },
  {
    value: "interestRateWibor",
    textField: "Wibor"
  },
  {
    value: "subsidy",
    textField: "Subsidy"
  },
  {
    value: "taxFactor",
    textField: "Tax Factor"
  },
  {
    value: "expensesVehicleRegistration",
    textField: "Expenses + Vehicle Registration"
  },
  {
    value: "additionalProductAccessories",
    textField: "Additional Product / Accessories"
  },
  {
    value: "prepaidExpensesPlusInterest",
    textField: "Prepaid Expenses + Interest"
  },
];
