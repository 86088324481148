/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import "../../styles/searchModule/searchModule.css";
import { useNavigate } from "react-router-dom";
import { getIcon } from "../../utils/iconUtils";
import { Input } from "@progress/kendo-react-inputs";
import { getStatusClass } from "../../utils/statusUtil";
import ColumnHider from "../shared/ColumnHider";
import { Pager } from "@progress/kendo-react-data-tools";
import { searchModuleColumns } from "./gridColumns";
import { validateUserPermission } from "../../utils/helpers";
import { getTranslation } from "../../common/translation";
import { useTranslation } from "react-i18next";
import { DateFilterCell } from "../shared/DateFilterCell";
import defaultMs from "../../api/debounce";
import { checkMarketAu } from "../../utils/helpers";
import { format } from "date-fns";

const SearchModule = ({
  searchModuleParams,
  getSearchModuleList,
  searchModuleList,
  setSearchModuleList,
  searchModuleListPage,
  currentUser
}) => {
  const { t } = useTranslation();
  const hasSearchOnLoad = localStorage.getItem("searchModuleFilters")
    ? true
    : false;
  const [isGridVisible, setIsGridVisible] = useState(false);
  const Navigate = useNavigate();
  const [page, setPage] = useState({
    skip: 0,
    take: 10
  });
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState(undefined);
  const [params, setParams] = useState(searchModuleParams);
  const [showColumnHider, setShowColumnHider] = useState(false);
  const [columns, setColumns] = useState(searchModuleColumns);
  const firstRender = useRef(true);
  const shouldUpdate = useRef(true);

  useEffect(() => {
    let fullQuery;
    if (firstRender.current) {
      if (hasSearchOnLoad) {
        const stored = JSON.parse(localStorage.getItem("searchModuleFilters"));
        fullQuery =
          queryBuilder(stored.params) +
          `&${stored.params.sort}${
            stored.params.sort ? "&" : ""
          }page=${JSON.stringify(stored.page)}&filters=[${
            stored.params.filters
          }]`;
        getSearchModuleList(fullQuery);
        setIsGridVisible(true);
        setFilter({
          ...stored.filter,
          filters: stored.filter?.filters?.map((filter) => {
            return ["lastActivity"].includes(filter.field)
              ? { ...filter, value: new Date(filter.value) }
              : filter;
          })
        });
        setParams(stored.params);
        setPage(stored.page);
        setPageSizeValue(stored.pageSizeValue);
        setSort(stored.sort);
        setTimeout(() => (firstRender.current = false), defaultMs / 2);
        localStorage.removeItem("searchModuleFilters");
      }
    } else {
      if (shouldUpdate.current) {
        fullQuery =
          queryBuilder() +
          `&${params.sort}${params.sort ? "&" : ""}page=${JSON.stringify(
            page
          )}&filters=[${params.filters}]`;
        const getData = setTimeout(
          () => getSearchModuleList(fullQuery),
          defaultMs
        );
        return () => clearTimeout(getData);
      }
      shouldUpdate.current = true;
    }
  }, [filter, sort]);

  const queryBuilder = (newparams = null) => {
    const parameters = newparams ?? params;
    let queryString = "";
    for (var key in parameters) {
      if (queryString !== "") {
        queryString += "&";
      }
      if (!["filters", "sort", "statusFilters", "page"].includes(key))
        queryString += key + "=" + parameters[key];
    }

    return `?${queryString}`;
  };

  const pageChange = (e) => {
    const targetEvent = e.targetEvent;
    const take = e.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    const pageData = {
      ...page,
      skip: e.skip,
      take
    };

    setPage(pageData);
    getSearchModuleList(
      queryBuilder() +
        `&${params.sort}${params.sort ? "&" : ""}page=${JSON.stringify(
          pageData
        )}&filters=[${params.filters}]`
    );
  };

  const handleButtonClick = () => {
    if (firstRender.current) {
      firstRender.current = false;
    }
    const emptyObj = {
      sort: "",
      filters: [],
      statusFilters: "",
      leadType: "",
      name: "",
      bank: "",
      postalCode: "",
      currentVehicle: "",
      contractId: "",
      phoneNumber: "",
      licensePlate: "",
      vinNumber: ""
    };

    if (JSON.stringify(params) !== JSON.stringify(emptyObj)) {
      const newpage = { ...page, skip: 0 };
      getSearchModuleList(queryBuilder() + `&page=${JSON.stringify(newpage)}`);
      setFilter(undefined);
      setSort([]);
      setPage(newpage);
      shouldUpdate.current = false;
      setParams({ ...params, filters: [], sort: "" });
      setIsGridVisible(true);
    } else {
      setSearchModuleList([]);
      setIsGridVisible(false);
    }
  };

  const handleSearch = (e) => {
    const key = e.target.props.name;
    const value = e.target.value;
    const numOnly = /^[0-9\s-]*$/; // only allows numbers, spaces, and "-"

    if (
      ["postalCode", "phoneNumber"].includes(key) ? numOnly.test(value) : true
    ) {
      setParams({
        ...params,
        [key]: value,
      });
    }
  };

  const handleSort = (e) => {
    setSort(e.sort);
    if (e.sort.length > 0) {
      let obj = { ...e.sort[0] };
      obj["direction"] = obj["dir"];
      delete obj["dir"];
      obj = JSON.stringify(obj);
      setParams({ ...params, sort: `sort=${obj}` });
    } else {
      setParams({ ...params, sort: "" });
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.dataState.filter);
    let filters = [];
    if (e.dataState.filter !== undefined) {
      filters = e.dataState.filter.filters.map((item) => {
        let obj = { ...item };
        if (obj.value instanceof Date) {
          obj.value = format(obj.value, 'yyyy-MM-dd');
        }
        obj["condition"] = obj["operator"];
        delete obj["operator"];
        obj = JSON.stringify(obj);
        return obj;
      });

      setParams({
        ...params,
        filters: filters
      });
      setPage({
        ...page,
        skip: 0
      });
    } else {
      filters = [];
      setParams({ ...params, filters: [] });
    }
  };

  const statusCell = (props) => {
    return (
      <td onClick={() => handleRowClick(props)}>
        <span className={getStatusClass(props.dataItem.statusName)}>
          {props.dataItem.statusName}
        </span>
      </td>
    );
  };

  const customPager = (props) => {
    return (
      <div className="custom-pager">
        <div className="imgs">
          <img
            className="column-hider"
            onClick={() => setShowColumnHider(true)}
            src={getIcon("settings.png")}
            alt="column hider icon"
          />
        </div>
        <Pager
          skip={props.skip}
          take={props.take}
          type="numeric"
          total={props.total}
          buttonCount={props.buttonCount}
          previousNext={true}
          pageSizes={props.pageSizes}
          pageSizeValue={props.pageSizeValue}
          onPageChange={pageChange}
        />
      </div>
    );
  };

  const handleRowClick = ({ dataItem }) => {
    const id =
      dataItem.statusName === "Record" ? dataItem.ivId : dataItem.leadId;
    if (validateUserPermission(currentUser.permissions, "canView", "Leads")) {
      localStorage.setItem(
        "searchModuleFilters",
        JSON.stringify({
          sort,
          params,
          page,
          pageSizeValue,
          filter
        })
      );
      Navigate(`/customer-record/${id}`, {
        state: { prevPage: "search-module" }
      });
    }
  };

  return (
    <div className="search-module">
      <p className="h1 mb-4">{getTranslation("Search Module", t)}</p>
      <div className="module-cont">
        <div className="header mb-2">
          <div className="search">
            <div>
              <div className="d-flex flex-column">
                <label>{getTranslation("Name", t)}</label>
                <Input
                  name="name"
                  onChange={handleSearch}
                  className="primary-input"
                  type="text"
                  value={params.name}
                />
              </div>
            </div>
            <div>
              <div className="d-flex flex-column">
                <label>{getTranslation("Contract ID", t)}</label>
                <Input
                  name="contractId"
                  onChange={handleSearch}
                  className="primary-input"
                  type="text"
                  value={params.contractId}
                />
              </div>
            </div>
            <div>
              <div className="d-flex flex-column">
                <label>{getTranslation("Post code", t)}</label>
                <Input
                  name="postalCode"
                  onChange={handleSearch}
                  className="primary-input"
                  type="text"
                  value={params.postalCode}
                />
              </div>
            </div>
            <div>
              <div className="d-flex flex-column">
                <label>{getTranslation("Bank/Seller", t)}</label>
                <Input
                  name="bank"
                  onChange={handleSearch}
                  className="primary-input"
                  type="text"
                  value={params.bank}
                />
              </div>
            </div>
            <div>
              <div className="d-flex flex-column">
                <label>{getTranslation("License plate", t)}</label>
                <Input
                  name="licensePlate"
                  onChange={handleSearch}
                  className="primary-input"
                  type="text"
                  value={params.licensePlate}
                />
              </div>
            </div>
            <div>
              <div className="d-flex flex-column">
                <label>{getTranslation("VIN", t)}</label>
                <Input
                  name="vinNumber"
                  onChange={handleSearch}
                  className="primary-input"
                  type="text"
                  value={params.vinNumber}
                />
              </div>
            </div>
            <div>
              <div className="d-flex flex-column">
                <label>{getTranslation("Current vehicle", t)}</label>
                <Input
                  name="currentVehicle"
                  onChange={handleSearch}
                  className="primary-input"
                  type="text"
                  value={params.currentVehicle}
                />
              </div>
            </div>
            <div>
              <div className="d-flex flex-column">
                <label>{getTranslation("Mobile No.", t)}</label>
                <Input
                  name="phoneNumber"
                  onChange={handleSearch}
                  className="primary-input"
                  type="text"
                  value={params.phoneNumber}
                />
              </div>
            </div>
            <div>
              <button
                className="search-btn primary-btn"
                onClick={handleButtonClick}
              >
                <img src={getIcon("searchInput.png")} alt="search icon" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {isGridVisible && (
        <div className="grid-cont">
          <Grid
            data={searchModuleList.map((item) => ({
              ...item,
              lastActivity: item.lastActivity
                ? new Date(item.lastActivity)
                : null
            }))}
            skip={page.skip}
            take={page.take}
            filter={filter}
            sortable={true}
            sort={sort}
            onSortChange={handleSort}
            total={searchModuleListPage.totalRecords}
            pager={customPager}
            pageable={{
              buttonCount:
                searchModuleListPage.totalPages > 10
                  ? 10
                  : searchModuleListPage.totalPages,
              pageSizes: [5, 10, 15],
              pageSizeValue
            }}
            filterable={true}
            onDataStateChange={handleFilterChange}
            onRowClick={(e) => handleRowClick(e)}
          >
            {columns.reduce((acc, current) => {
              const returns = acc + (!current.isShown ? 1 : 0);
              return returns;
            }, 0) === columns.length ? (
              <Column field="" title="" filterable={false} />
            ) : (
              columns.map((column, idx) => {
                if (
                  [getTranslation("Customer Name", t)].includes(
                    getTranslation(column.title, t)
                  )
                ) {
                  return column.isShown && !checkMarketAu() ? (
                    <Column
                      key={idx}
                      field={column.column}
                      title={getTranslation(column.title, t)}
                      filterable={true}
                      cell={!idx ? statusCell : undefined}
                      width={column.width}
                      filter={column.filter}
                    />
                  ) : null;
                }
                return column.isShown ? (
                  <Column
                    key={idx}
                    field={column.column}
                    title={getTranslation(column.title, t)}
                    filterable={true}
                    cell={!idx ? statusCell : undefined}
                    width={column.width}
                    filter={column.filter}
                    filterCell={
                      column.filter === "date" ? DateFilterCell : undefined
                    }
                    format={
                      column.filter === "date" ? "{0:dd/MM/yyyy}" : undefined
                    }
                  />
                ) : null;
              })
            )}
          </Grid>
        </div>
      )}
      {showColumnHider && (
        <ColumnHider
          closeHider={() => setShowColumnHider(false)}
          columns={
            checkMarketAu()
              ? columns.filter(
                  (column) =>
                    getTranslation(column.title, t) !==
                    getTranslation("Customer Name", t)
                )
              : columns
          }
          setColumns={setColumns}
        />
      )}
    </div>
  );
};

export default SearchModule;
